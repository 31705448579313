import React from "react"
import styled from "@emotion/styled"

const GlobalMessage = () => {
  return (
    <MessageContainer>
      <MessageBox></MessageBox>
    </MessageContainer>
  )
}
export default GlobalMessage

const MessageContainer = styled.div`
  width: 100%;
  max-width: "500px";
  display: flex;
  justify-content: center;
`

const MessageBox = styled.div`
  margin: 10px;
  padding: 10px 10px;
  border-radius: 7px;
  font-size: 13px;
  color: #4b4b65;
  background: #fafafc;
  box-shadow: 1px 1px 3px #69696982;
  margin-bottom: 5px;
  max-width: 558px;
`
