import React from "react"

const EvoliLogo = ({ color, width }) => {
  return <EvoliNameLogo color={color} width={width} />
}
export default EvoliLogo

const EvoliNameLogo = ({ color, width }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} viewBox="0 0 169 58">
      <g fill={color} fillRule="evenodd">
        <path d="M21.37 19.957c-6.497 0-12.036 4.141-14.103 9.94a3.742 3.742 0 01-7.05-2.514c3.097-8.685 11.393-14.91 21.153-14.91 6.309 0 12.016 2.607 16.09 6.793a3.742 3.742 0 01-.036 5.256L20.108 41.838a3.742 3.742 0 01-5.292-5.292L29.2 22.164a14.888 14.888 0 00-7.83-2.207zM2.485 37.685a3.742 3.742 0 014.782 2.268c2.067 5.798 7.606 9.94 14.103 9.94s12.036-4.142 14.103-9.94a3.742 3.742 0 017.05 2.513c-3.097 8.685-11.393 14.91-21.153 14.91-9.76 0-18.056-6.225-21.153-14.91a3.742 3.742 0 012.268-4.781zM45.735 12.863a3.742 3.742 0 015.015 1.69l15.385 31.026 15.652-31.048a3.742 3.742 0 016.684 3.369L69.449 55.63a3.742 3.742 0 01-6.694-.021l-18.71-37.732a3.742 3.742 0 011.69-5.014zM118.553 13.938c8.684 3.096 14.91 11.393 14.91 21.152 0 9.76-6.226 18.056-14.91 21.153a3.742 3.742 0 01-2.514-7.05c5.798-2.067 9.94-7.606 9.94-14.103 0-6.496-4.142-12.036-9.94-14.103a3.742 3.742 0 112.514-7.05zm-10.303 2.268a3.742 3.742 0 01-2.267 4.781c-5.799 2.067-9.94 7.607-9.94 14.103 0 6.497 4.141 12.036 9.94 14.104a3.742 3.742 0 11-2.514 7.049c-8.685-3.097-14.91-11.393-14.91-21.153 0-9.76 6.225-18.056 14.91-21.152a3.742 3.742 0 014.781 2.268zM143.129 0a3.742 3.742 0 013.742 3.742V44.28c0 1.886.072 3.258.577 4.21.19.358.44.637.864.865.465.248 1.342.537 2.925.537a3.742 3.742 0 110 7.484c-2.472 0-4.635-.45-6.45-1.42a9.45 9.45 0 01-3.95-3.959c-1.459-2.75-1.453-5.9-1.45-7.517V3.742A3.742 3.742 0 01143.13 0zM162.774 12.473a3.742 3.742 0 013.742 3.742v37.42a3.742 3.742 0 11-7.484 0v-37.42a3.742 3.742 0 013.742-3.742z" />
        <path
          fillRule="nonzero"
          d="M167.14 4.366a4.366 4.366 0 11-8.731 0 4.366 4.366 0 018.73 0z"
        />
      </g>
    </svg>
  )
}
