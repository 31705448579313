import React from "react"
import { bool } from "prop-types"
import { StyledMenu } from "./Menu.styled"
import { Link } from "gatsby"

const Menu = ({ open }) => {
  const tabIndex = open ? 0 : -1

  return (
    <StyledMenu open={open} aria-hidden={!open}>
      <ul>
        <li>
          <Link to="/" tabIndex={tabIndex}>
            Bilförsäkring
          </Link>
        </li>
        <li>
          <Link to="/forsakringar/produkt" tabIndex={tabIndex}>
            Produktförsäkring
          </Link>
        </li>
        <li>
          <Link to="/forsakringar/hem" tabIndex={tabIndex}>
            Hemförsäkring
          </Link>
        </li>
        <li>
          <a
            href="https://support.evoli.se/sv/articles/3778940-anmal-skada"
            tabIndex={tabIndex}
          >
            Anmäl skada
          </a>
        </li>
        <li>
          <a href="https://support.evoli.se" tabIndex={tabIndex}>
            Support
          </a>
        </li>
        <li>
          <Link to="/om-oss" tabIndex={tabIndex}>
            Om oss
          </Link>
        </li>
      </ul>
    </StyledMenu>
  )
}

Menu.propTypes = {
  open: bool.isRequired,
}

export default Menu
