import React, { useRef, useContext } from "react"
import FocusLock from "react-focus-lock"
import styled from "@emotion/styled"
import { useTheme } from "emotion-theming"
import { useOnClickOutside } from "../../utils/MenuHook"
import { Link } from "gatsby"
import Burger from "./Burger/Burger"
import Menu from "./Menu/"
import { QuoteContext } from "../../Context/QuoteContext"
import { toggleMenu } from "../../Context/Actions"
import EvoliLogo from "./EvoliLogo/Logo"

const Header = () => {
  const { state, dispatch } = useContext(QuoteContext)
  const theme = useTheme()
  const node = useRef()

  useOnClickOutside(node, () => dispatch(toggleMenu(false)))

  return (
    <HeaderContainer>
      <MenuContainer>
        <div ref={node}>
          <FocusLock disabled={!state.showMenu}>
            <Burger aria-controls="meny" />
            <Menu open={state.showMenu} id="main-menu" />
          </FocusLock>
        </div>
      </MenuContainer>

      <LogoContainer>
        <Logo>
          <Link to="/">
            <div>
              <EvoliLogo color={theme.colors.header.headerLogoColor} />
            </div>
          </Link>
        </Logo>
      </LogoContainer>
      <QuickLinks>
        <Navigation>
          <ul>
            <li>
              <a href="https://support.evoli.se">Support</a>
            </li>
            <li>
              <a href="https://support.evoli.se/sv/articles/3778940-anmal-skada">
                Anmäl skada
              </a>
            </li>
          </ul>
        </Navigation>
      </QuickLinks>
    </HeaderContainer>
  )
}

export default Header

const QuickLinks = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`
const MenuContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  z-index: 1;
`

const LogoContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`

const HeaderContainer = styled.header`
  width: 100%;
  background-image: ${({ theme }) => theme.colors.header.headerBackground};
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  padding-top: 15px;

  @media (max-width: 960px) {
    padding: 15px 15px 0 15px;
  }
`

const Navigation = styled.nav`
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;

    @media (max-width: 950px) {
      display: none;
    }
  }

  li {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #ffffff;
    margin: 0;

    a {
      display: inline-block;
      padding: 10px 15px;
      text-decoration: none;
      color: #4b4b65;
    }

    a:hover {
      text-decoration: underline;
    }
  }
`

// const LogoSubTitle = styled.p`
//   height: 13px;
//   font-size: 11px;
//   font-weight: 500;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: normal;
//   color: #ffe428;
//   margin: 0 auto;

//   @media (max-width: 960px) {
//     display: none;
//   }
// `

const Logo = styled.div`
  max-width: 125px;
  /* padding: 9px 0 9px 0; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 31;

  svg {
    width: 106px;
  }

  a {
    font: initial;
  }

  @media (max-width: 960px) {
    svg {
      width: 79px;
    }
  }
`
