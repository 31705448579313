import React from "react"
import styled from "@emotion/styled"
import { useTheme } from "emotion-theming"
import AppStore from "../../images/download-on-the-app-store-badge-se-rgb-wht-100317.svg"
import GooglePlay from "../../images/ha-mta-i-google-play.svg"
import { Link } from "gatsby"
import EvoliLogo from "./EvoliLogo/Logo"

const Footer = ({ sticky }) => {
  const theme = useTheme()

  return (
    <FooterContainer sticky={sticky}>
      <FooterContent>
        <FooterHeader>
          <Link to="/">
            <EvoliLogo color={theme.colors.Footer.logoColor} width="125" />
          </Link>
        </FooterHeader>

        <LinkContainer>
          <LinkItem>
            <ul>
              <li>Support</li>
              <li>
                <a href="tel:0660660666">Ring: 0660-66 06 66</a>
              </li>
              <li>
                <a href="https://support.evoli.se">Kontakta oss</a>
              </li>
              <li>
                <a href="https://support.evoli.se/sv/articles/3778940-anmal-skada">
                  Anmäl skada
                </a>
              </li>

              <li>
                <a href="https://support.evoli.se/sv/collections/1825554-bilforsakring">
                  Vanliga frågor
                </a>
              </li>
            </ul>
          </LinkItem>
          <LinkItem>
            <ul>
              <li>Om oss</li>
              <li>
                <Link to="/om-oss/">Vilka är evoli</Link>
              </li>
              <li>
                <Link to="/om-oss/jobb-och-karriar">Jobb och karriär</Link>
              </li>
            </ul>
          </LinkItem>
          <LinkItem>
            <ul>
              <li>Legalt</li>
              <li>
                <Link to="/personuppgifter">Personuppgiftspolicy (GDPR)</Link>
              </li>
              <li>
                <Link to="/om-formedling">Om förmedling</Link>
              </li>
              <li>
                <Link to="/villkor">Villkor</Link>
              </li>
              <li>
                <a href="https://support.evoli.se/sv/articles/3787706-klagomal-har-vi-begatt-ett-misstag">
                  Klagomål
                </a>
              </li>
            </ul>
          </LinkItem>
        </LinkContainer>
        <EvoliApp>
          <a href="https://apps.apple.com/se/app/evoli/id1499910386">
            <img src={AppStore} alt="Länk till App Store" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=se.evoli.android">
            <img src={GooglePlay} alt="Länk till Google Play" />
          </a>
        </EvoliApp>
        <FooterParagraph>
          © Copyright evoli AB. Kungsgatan 64, 111 22 Stockholm. Org.nr:
          559180-0445. <br />
          evoli står under tillsyn av och har tillstånd av Finansinspektionen.
          Försäkringsgivare är Eir Försäkring AB.
          <br />
          På evoli.se används cookies för att föra statistik, hantera formulär
          och sessioner m.m.{" "}
          <a href="https://support.evoli.se/sv/articles/3785093-om-cookies">
            Läs mer
          </a>{" "}
          om cookies.
        </FooterParagraph>
        <FooterParagraph style={{ marginTop: "10px" }}>
          Google Play och logotypen för Google Play är varumärken som tillhör
          Google LLC.
          <br /> Apple, the Apple logo, iPhone, and iPad are trademarks of Apple
          Inc., registered in the U.S. and other countries and regions. App
          Store is a service mark of Apple Inc.
        </FooterParagraph>
      </FooterContent>
    </FooterContainer>
  )
}

export default Footer

const FooterContent = styled.div`
  max-width: 1440px;
  margin: 0 auto;
`

const FooterParagraph = styled.p`
  margin: 0;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #a0a3cf;
  line-height: 1.5;

  @media (max-width: 960px) {
    font-size: 12px;
    line-height: 1.25;
    margin-bottom: 10px;
  }

  a {
    color: inherit;
    text-decoration: underline;
  }
`

const EvoliApp = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 213px;
  margin-bottom: 45px;
  align-items: center;
  justify-content: space-between;
  img {
    margin: 0;
    max-width: 128px;
    width: 98px;
    height: 32px;
  }
`

const LinkItem = styled.div`
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => props.theme.colors.Footer.linkHeader};

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    list-style: none;

    @media (max-width: 960px) {
      margin-bottom: 25px;
    }

    li {
      margin-bottom: 10px;

      @media (max-width: 960px) {
        margin-bottom: 0;
      }
    }

    li a {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: ${props => props.theme.colors.Footer.linkColor};

      :hover {
        text-decoration: underline;
      }
    }
  }
`

const LinkContainer = styled.div`
  max-width: 640px;
  margin: 53px 0 30px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 960px) {
    flex-direction: column;
    margin-bottom: 0;
  }
`

const FooterHeader = styled.div`
  flex: 1;
  display: flex;
`

const FooterContainer = styled.footer`
  width: 100%;
  background-color: ${props => props.theme.colors.Footer.background};
  padding: 77px 60px;

  margin-bottom: ${props => (props.sticky ? "100px" : "0px")};

  @media (max-width: 960px) {
    padding: 30px 20px;
    text-align: left;
  }

  label {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
    font-size: 14px;
    color: #787cb1;
    svg {
      margin-left: 5px;
    }
  }
`
