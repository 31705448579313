import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet-async"

function SEO({ description, lang, meta, title, noIndex, referrer }) {
  const metaDescription = description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      // titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        referrer?.length > 0
          ? {
              name: `referrer`,
              content: referrer,
            }
          : {},
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: `https://evoli.se/evoli.png`,
        },
        {
          name: `robots`,
          content: noIndex ? "noindex" : "index",
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `sv-se`,
  meta: [],
  description: ``,
  noIndex: false,
  title: "evoli - En trygg bilförsäkring till lågt pris!",
  referrer: "",
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  noIndex: PropTypes.bool,
  referrer: PropTypes.string,
}

export default SEO
