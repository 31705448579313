import styled from "@emotion/styled"

export const StyledBurger = styled.button`
  position: ${({ open }) => (open ? "fixed" : "")};
  top: ${({ open }) => (open ? "21px" : "")};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 26px;
  height: 26px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  @media (max-width: 960px) {
    width: 23px;
    height: 21px;
    top: ${({ open }) => (open ? "23px" : "")};
  }

  span {
    width: 26px;
    height: 2px;
    background: ${({ theme, open }) =>
      open ? theme.colors.menu.burgerColorOpen : theme.colors.menu.burgerColor};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
    :first-of-type {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }
    :nth-of-type(2) {
      opacity: ${({ open }) => (open ? "0" : "1")};
    }
    :nth-of-type(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }

    @media (max-width: 960px) {
      width: 23px;
      height: 2px;
    }
  }
`
