import React, { useContext } from "react"
import { StyledBurger } from "./Burger.styled"
import { QuoteContext } from "../../../Context/QuoteContext"
import { toggleMenu } from "../../../Context/Actions"

const Burger = () => {
  const { state, dispatch } = useContext(QuoteContext)

  const onClickHandler = e => {
    dispatch(toggleMenu(!state.showMenu))
  }

  return (
    <>
      <StyledBurger
        aria-label={state.showMenu ? "Stäng meny" : "Öppna meny"}
        aria-expanded={state.showMenu}
        open={state.showMenu}
        onClick={onClickHandler}
      >
        <span />
        <span />
        <span />
      </StyledBurger>
    </>
  )
}

export default Burger
