import styled from "@emotion/styled"

export const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #151625;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-101%)")};
  height: 100vh;
  text-align: left;

  position: fixed;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;

  @media (max-width: 950px) {
    width: 100%;
    /* overflow: scroll; */
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0 100px;

    @media (max-width: ${({ theme }) => theme.sizes.mobile}) {
      padding: 0;
    }

    @media (max-height: 414px) {
      margin-top: 160px;
    }

    @media (max-height: 375px) {
      margin-top: 130px;
    }

    @media (max-height: 320px) {
      margin-top: 160px;
    }

    li {
      text-align: center;
      margin-bottom: 40px;
      :last-of-type {
        margin-bottom: 0;
      }
    }
  }

  a {
    font-size: 1.5rem;
    text-transform: uppercase;

    font-weight: normal;
    letter-spacing: 0.1rem;
    color: #47ecb4;

    text-decoration: none;
    transition: color 0.3s linear;
    @media (max-width: ${({ theme }) => theme.sizes.mobile}) {
      font-size: 1.2rem;
      text-align: center;
    }
    &:hover {
      color: ${({ theme }) => theme.colors.menu.menuColorHover};
    }
  }
`
