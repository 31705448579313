import React, { useContext, useEffect } from "react"
import styled from "@emotion/styled"
import "./layout.css"
import Header from "./GlobalAssets/Header"
import Footer from "./GlobalAssets/Footer"
import { QuoteContext } from "../Context/QuoteContext"
import { toggleMenu } from "../Context/Actions"
import "focus-visible"
import GlobalMessage from "./GlobalAssets/GlobalMessage/globalMessage"

const Layout = ({ children, location }) => {
  const { state, dispatch } = useContext(QuoteContext)

  let stickyFix = false

  if (location !== undefined) {
    if (location.pathname.split("/")[2] === "offert") {
      stickyFix = true
    }
  }

  useEffect(() => {
    if (state.showMenu) {
      document.body.classList.add("menu-open")
    } else {
      document.body.classList.remove("menu-open")
    }
  }, [state.showMenu])

  useEffect(() => {
    if (state.showMenu) {
      dispatch(toggleMenu(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

    if (isMobile && window.Intercom !== undefined) {
      window.Intercom("update", {
        hide_default_launcher: true,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <SiteContainer>
      <Header />
      {/* <GlobalMessage /> */}

      <PageContent>{children}</PageContent>
      <Footer sticky={stickyFix} />
      {process.env.GATSBY_ACTIVE_ENV === "staging" && (
        <EnvDot title="Test environment" />
      )}
    </SiteContainer>
  )
}

export default Layout

const EnvDot = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 100%;
  background: #f48e369e;
  position: fixed;
  right: 5px;
  top: 5px;
`
const PageContent = styled.div`
  flex: 1 1 auto;
`

const SiteContainer = styled.div`
  display: flex;
  height: 100%;
  min-height: 100vh;
  flex-direction: column;
`
